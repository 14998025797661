import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { UploadService } from '../services/upload.service';

export const hasUploadId: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const extClaimId = childRoute.params['extClaimId'];
  const extClientId = childRoute.params['extClientId'];
  const roleType = childRoute.params['roleType'];
  const response = inject(UploadService).getUploadId2(extClaimId, extClientId, roleType);

  return response;
};
