import { Route } from '@angular/router';
import { ContractComponent } from 'rapid-reimbursement-ui-cov-a/src/pages/contract/contract.component';
import { LandingComponent } from 'rapid-reimbursement-ui-cov-a/src/pages/landing/landing.component';
import { ReviewComponent } from 'rapid-reimbursement-ui-cov-a/src/pages/review/review.component';
import { ConfirmationComponent } from '../pages/confirmation/confirmation.component';
import { ConnectionErrorComponent } from '../pages/connection-error/connection-error.component';
import { InvalidRequestErrorComponent } from '../pages/invalid-request-error/invalid-request-error.component';
import { MalformedRequestErrorComponent } from '../pages/malformed-request-error/malformed-request-error.component';
import { UnresolvableErrorComponent } from '../pages/unresolvable-error/unresolvable-error.component';
import { UploadComponent } from '../pages/upload/upload.component';
import { hasUploadId } from '../guards/upload-id.guard';

export const appRoutes: Route[] = [
  { path: 'claim/:extClaimId/client/:extClientId/role/:roleType', component: LandingComponent },
  { path: 'claim/:extClaimId/client/:extClientId/role/:roleType/confirmation', component: ConfirmationComponent },
  {
    path: 'claim/:extClaimId/client/:extClientId/role/:roleType',
    canActivateChild: [hasUploadId],
    children: [
      { path: 'contract/:contractId/edit', component: ContractComponent },
      { path: 'contract/:contractId/view', component: ContractComponent },
      { path: 'review', component: ReviewComponent },
      { path: 'upload', component: UploadComponent },
    ],
  },
  { path: 'claim/:extClaimId/client/:extClientId/role/:roleType/contract', component: ContractComponent },
  { path: 'error-connection', component: ConnectionErrorComponent },
  { path: 'error-invalid-request', component: InvalidRequestErrorComponent },
  { path: 'error-unresolvable', component: UnresolvableErrorComponent },
  { path: 'error-malformed-request', component: MalformedRequestErrorComponent },
  { path: '**', redirectTo: 'error-malformed-request' },
];
