import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KEYS, SFLogger } from 'sf-logger-javascript';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environments/environment';
import { CorrelationIdService } from './correlation-id.service';

export enum LogLevel {
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export interface SparklLog {
  messageId: string;
  message: string;
  logLevel: LogLevel;
  reportingFile: string;
  contractId: string;
  uploadId: string;
  calledService: string;
  httpResponse: string;
  httpStatus: string;
  contractor: string;
  contractDateSigned: string;
  numberOfPhotos: string;
  imageId: string;
  fileType: string;
  fileName: string;
  fileSize: string;
}

const loggerConfig = {
  sparkl: {
    env: environment.splunk.env,
    endpointType: 'public',
  },
  httpLoggingEnabled: true,
  consoleLoggingEnabled: false,
  includeReportingLocation: false,
};

let correlationId = sessionStorage.getItem('CORRELATION_ID');
if (!correlationId) {
  correlationId = uuidv4();
  sessionStorage.setItem('CORRELATION_ID', correlationId);
}

const logger = SFLogger(
  loggerConfig,
  KEYS.APPLICATION_ID,
  'rapid-reimbursement-ui',
  KEYS.CORRELATION_ID,
  correlationId
);

@Injectable()
export class LoggingService {
  constructor(private http: HttpClient, private correlationId: CorrelationIdService) {}

  sendSparklLog(sparklLog: SparklLog) {
    logger.setAuthToken(`${environment.splunk.api}`);

    const entryPoint = 'COV_A';

    switch (sparklLog.logLevel) {
      case 'INFO':
        logger.info(
          sparklLog.messageId,
          sparklLog.message,
          'entryPoint',
          entryPoint,
          'reportingFile',
          sparklLog.reportingFile,
          'externalClaimId',
          localStorage.getItem('extClaimId') ?? 'no-value-found',
          'externalClientId',
          localStorage.getItem('extClientId') ?? 'no-value-found',
          'roleType',
          localStorage.getItem('roleType') ?? 'no-value-found',
          'uploadId',
          sparklLog.uploadId,
          'contractId',
          sparklLog.contractId,
          'uploadId',
          sparklLog.uploadId,
          'calledService',
          sparklLog.calledService,
          'httpResponse',
          sparklLog.httpResponse,
          'httpStatus',
          sparklLog.httpStatus,
          'contractor',
          sparklLog.contractor,
          'contractDateSigned',
          sparklLog.contractDateSigned,
          'numberOfPhotos',
          sparklLog.numberOfPhotos,
          'imageId',
          sparklLog.imageId,
          'fileType',
          sparklLog.fileType,
          'fileName',
          sparklLog.fileName,
          'fileSize',
          sparklLog.fileSize
        );
        break;
      case 'ERROR':
        logger.error(
          sparklLog.messageId,
          sparklLog.message,
          'entryPoint',
          entryPoint,
          'reportingFile',
          sparklLog.reportingFile,
          'externalClaimId',
          localStorage.getItem('extClaimId') ?? 'no-value-found',
          'externalClientId',
          localStorage.getItem('extClientId') ?? 'no-value-found',
          'roleType',
          localStorage.getItem('roleType') ?? 'no-value-found',
          'uploadId',
          sparklLog.uploadId,
          'contractId',
          sparklLog.contractId,
          'uploadId',
          sparklLog.uploadId,
          'calledService',
          sparklLog.calledService,
          'httpResponse',
          sparklLog.httpResponse,
          'httpStatus',
          sparklLog.httpStatus,
          'contractor',
          sparklLog.contractor,
          'contractDateSigned',
          sparklLog.contractDateSigned,
          'numberOfPhotos',
          sparklLog.numberOfPhotos,
          'imageId',
          sparklLog.imageId,
          'fileType',
          sparklLog.fileType,
          'fileName',
          sparklLog.fileName,
          'fileSize',
          sparklLog.fileSize
        );
        break;
    }
  }
}
