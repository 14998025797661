import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { environment } from 'rapid-reimbursement-ui-cov-a/src/environments/environment';
import { LoggingService, LogLevel } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { v4 as uuidv4 } from 'uuid';
import { AnalyticsService } from '../../shared/analytics/analytics.service';
@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent {
  screenId = 'submission';
  //
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {}
  ngOnInit() {
    const extClaimId = this.route.snapshot.paramMap.get('extClaimId');
    const extClientId = this.route.snapshot.paramMap.get('extClientId');
    const roleType = this.route.snapshot.paramMap.get('roleType');
    const baseUrl = `/claim/${extClaimId}/client/${extClientId}/role/${roleType}`;
    localStorage.setItem('extClaimId', extClaimId ?? 'no-extClaimId');
    localStorage.setItem('extClientId', extClientId ?? 'no-extClientId');
    localStorage.setItem('roleType', roleType ?? 'no-roleType');
    localStorage.setItem('baseUrl', baseUrl);
    let correlationId = sessionStorage.getItem('CORRELATION_ID');
    if (!correlationId) {
      correlationId = uuidv4();
      sessionStorage.setItem('CORRELATION_ID', correlationId);
    }
    this.logger.sendSparklLog({
      messageId: 'COVA_CONFIRMATION_PAGE',
      message: 'Confirmation page',
      logLevel: LogLevel.INFO,
      reportingFile: 'confirmation.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: localStorage.getItem('extClaimId')!,
      externalClientId: localStorage.getItem('extClientId')!,
      roleType: localStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }

  goToLanding() {
    this.logger.sendSparklLog({
      messageId: 'COVA_CONFIRMATION_GO_TO_LANDING',
      message: 'Go to landing',
      logLevel: LogLevel.INFO,
      reportingFile: 'confirmation.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.ngZone.run(() => {
      this.router.navigate([localStorage.getItem('baseUrl')], {
        queryParamsHandling: 'merge',
      });
    });
  }

  goToClaimsHub() {
    this.logger.sendSparklLog({
      messageId: 'COVA_CONFIRMATION_GO_TO_CLAIMS_HUB',
      message: 'Go to Claims Hub',
      logLevel: LogLevel.INFO,
      reportingFile: 'confirmation.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    window.open(`${environment.claims_hub}${localStorage.getItem('baseUrl')}`);
  }
}
