import axios from "axios";
import defaultConfig from "./config.json";
import { ENDPOINT_TYPE, InternalKeys, CoreKeys, ENV } from "./constants";
import * as stackTraceParser from "stacktrace-parser";
//log the actual event to given destination (stdout or http).
export function logEvent(logger, level, messageId, message, ...args) {
    const { core, config } = logger;
    let eventMap = getEventMap(core, level, messageId, message, ...args);
    if (config.includeReportingLocation) {
        eventMap = addLocation(eventMap);
    }
    if (config.httpLoggingEnabled) {
        writeToHTTP(logger, eventMap);
    }
    if (config.consoleLoggingEnabled) {
        writeToSTDOUT(eventMap);
    }
}
//combine input parameters with core logger values into an event map.
function getEventMap(core, level, messageId, message, ...args) {
    let map1 = argsToMap(InternalKeys.LOG_LEVEL, level, InternalKeys.MESSAGE_ID, messageId, InternalKeys.MESSAGE, message);
    let map2 = argsToMap(...args);
    let m1m2 = getNewCombinedMap(map1, map2);
    let contextMap = contextArgsToMap(...args);
    let coreCombinedMap = getNewCombinedMap(core, contextMap);
    m1m2.set("context", coreCombinedMap);
    return m1m2;
}
//make a new map using a random array of args passed in
export function argsToMap(...args) {
    let map = new Map();
    for(let x = 0; x < args.length - 1; x += 2){
        let key = args[x];
        map.set(key, args[x + 1]);
    }
    return map;
}
// //make a new map including only keys from CoreKeys
function contextArgsToMap(...args) {
    let map = new Map();
    const coreKeys = Object.values(CoreKeys);
    for(let x = 0; x < args.length - 1; x += 2){
        let key = args[x];
        if (!coreKeys.includes(key)) continue;
        map.set(key, args[x + 1]);
    }
    return map;
}
//make a new map from the maps passed in
function getNewCombinedMap(inMap1, inMap2) {
    let newMap1 = new Map(inMap1);
    let newMap2 = new Map(inMap2);
    let combinedMap = new Map([
        ...newMap1,
        ...newMap2
    ]);
    return combinedMap;
}
//parse the stack to determine the file and line number where this log request originated
function addLocation(eventMap) {
    try {
        const err = new Error();
        let stack = stackTraceParser.parse(err.stack);
        let entry = stack[4];
        eventMap.get("context").set(CoreKeys.REPORTING_FILE, getFile(entry.file));
        eventMap.get("context").set(CoreKeys.LINE_NUMBER, entry.lineNumber);
    } catch (e) {
        eventMap.get("context").set(CoreKeys.REPORTING_FILE, "unknown");
        eventMap.get("context").set(CoreKeys.LINE_NUMBER, "unknown");
    }
    return eventMap;
}
//parse file path for file name
export function getFile(longFile) {
    let fileParts = longFile.split(/[\\/]/);
    return fileParts[fileParts.length - 1];
}
function writeToSTDOUT(inMap) {
    let json = mapToJsonString(inMap);
    switch(inMap.get("logLevel")){
        case "info":
            console.info(json);
            break;
        case "warn":
            console.warn(json);
            break;
        case "error":
            console.error(json);
            break;
        case "debug":
            console.debug(json);
            break;
        default:
            console.log(json);
    }
}
async function writeToHTTP(logger, inMap) {
    const { token, config } = logger;
    let json = mapToJsonString(inMap);
    let httpDestination = updateHttpDestination(config);
    if (httpDestination !== "") {
        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": defaultConfig.http.headers["Content-Type"],
            Accept: defaultConfig.http.headers["Accept"],
            Index: logger.index
        };
        axios.post(httpDestination, json, {
            headers
        }).then((response)=>{
            return response;
        }).catch((error)=>{
            console.error("Error writing to HTTP: " + error);
        });
    } else {
        console.error("Error writing to HTTP: Sparkl endpoint could not be determined from config.");
    }
}
// convert map to json string for testing
function mapToJsonString(inMap) {
    let obj = {};
    for (let x of inMap){
        let key = x[0];
        let val = x[1];
        if (key === "context") {
            obj[key] = {};
            for (let y of val){
                let contextKey = y[0];
                let contextVal = y[1];
                obj[key][contextKey] = contextVal;
            }
            continue;
        }
        obj[key] = val;
    }
    return obj;
}
function updateHttpDestination(config) {
    let env = config.sparkl?.env || defaultConfig.env;
    let endpointType = config.sparkl?.endpointType || defaultConfig.endpointType;
    if (env === ENV.TEST && endpointType === ENDPOINT_TYPE.PRIVATE) {
        return defaultConfig.http.destination.test.private.url;
    }
    if (env === ENV.TEST && endpointType === ENDPOINT_TYPE.PUBLIC) {
        return defaultConfig.http.destination.test.public.url;
    }
    if (env === ENV.PROD && endpointType === ENDPOINT_TYPE.PRIVATE) {
        return defaultConfig.http.destination.prod.private.url;
    }
    if (env === ENV.PROD && endpointType === ENDPOINT_TYPE.PUBLIC) {
        return defaultConfig.http.destination.prod.public.url;
    }
    return "";
}
//sets the core key values from a list of arguments
export function setContext(core, ...args) {
    for(let index = 0; index < args.length - 1; index += 2){
        let key = args[index];
        let value = args[index + 1];
        core.set(key, value);
    }
}
