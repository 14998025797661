import { HttpInterceptorFn } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

export const correlationIdInterceptor: HttpInterceptorFn = (req, next) => {
  const correlationId = getCorrelationId();
  if (correlationId) {
    const cloned = req.clone({
      setHeaders: {
        correlationId,
      },
    });

    return next(cloned);
  }
  return next(req);
};

function getCorrelationId() {
  let correlationId = sessionStorage.getItem('CORRELATION_ID');
  if (!correlationId) {
    correlationId = uuidv4();
    sessionStorage.setItem('CORRELATION_ID', correlationId);
  }
  return correlationId;
}
