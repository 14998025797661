import { CoreKeys, ENV, ENDPOINT_TYPE } from "./constants";
export function validateArgs(args) {
    if (args.length % 2 !== 0) {
        console.warn("Number of arguments passed to log function is not even, output may not be as expected.");
    }
}
export function validateCore(core) {
    if (!core.has(CoreKeys.CORRELATION_ID)) {
        console.warn("CorrelationId is missing from context. Use setContext() to set it.");
    }
    if (!core.has(CoreKeys.APPLICATION_ID)) {
        console.warn("ApplicationId is missing from context. Use setContext() to set it.");
    }
}
export function validateConfig(config) {
    if (!config.httpLoggingEnabled) {
        return;
    }
    if (config.sparkl?.env !== ENV.TEST && config.sparkl?.env !== ENV.PROD) {
        console.error("env is missing or invalid in config. Must be 'test' or 'prod'.");
    }
    if (config.sparkl?.endpointType !== ENDPOINT_TYPE.PRIVATE && config.sparkl?.endpointType !== ENDPOINT_TYPE.PUBLIC) {
        console.error("endpointType is missing or invalid in config. Must be 'private' or 'public'.");
    }
}
export function validateHTMLCharacters(core, messageId, message, args) {
    const entryList = [
        ...core.keys(),
        ...core.values(),
        messageId,
        message,
        ...args
    ];
    const matchedValue = entryList.find((value)=>{
        return containsHTMLCharacters(value);
    });
    if (matchedValue) {
        console.error(`HTML characters (<, >, &) found within the following key/values: ${matchedValue}.` + ` These cannot be logged when logging via SPARKL API. If you need to log using these characters, use encodeURIComponent() to encode it first.`);
    }
}
function containsHTMLCharacters(value) {
    if (typeof value !== "string") return false;
    if (value.match(/[<>&]/) !== null) return true;
    return false;
}
