import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { correlationIdInterceptor } from '../interceptors/correlation-id.interceptor';
import { contractsReducer } from '../ngrx/contracts.reducer';
import { AuthLocalService } from '../services/authlocal.service';
import { CorrelationIdService } from '../services/correlation-id.service';
import { LoggingService } from '../services/logging.service';
import { UploadService } from '../services/upload.service';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';

const tokenGetter = () => {
  const authService = inject(AuthLocalService);
  const pathParts = window.location.pathname.split('/');
  if (pathParts.length < 7) {
    return null;
  }
  const extClaimId = pathParts[2];
  const extClientId = pathParts[4];
  const roleType = pathParts[6];
  const baseUrl = `/claim/${extClaimId}/client/${extClientId}/role/${roleType}`;
  localStorage.setItem("extClaimId", extClaimId);
  localStorage.setItem("extClientId", extClientId);
  localStorage.setItem("roleType", roleType);
  localStorage.setItem('baseUrl', baseUrl);
  return authService.getToken(extClaimId, extClientId);
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideStoreDevtools({ logOnly: !isDevMode() }),
    provideEffects(),
    provideStore(),
    provideState({ name: 'contracts', reducer: contractsReducer }),
    provideRouter(appRoutes),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: [environment.api_domain],
          throwNoTokenError: true,
        },
      })
    ),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([correlationIdInterceptor])),
    LoggingService,
    UploadService,
    CorrelationIdService,
  ],
};
