var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//*--------------------------------------------------------------------------------------------------
//*  Map usage: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
//*--------------------------------------------------------------------------------------------------
import axios from "axios";
import defaultConfig from "./config.json";
import { CoreKeys, ENDPOINT_TYPE, ENV, InternalKeys, INDEXES, } from "./constants.js";
import { argsToMap, getFile } from "./util.js";
export class Logger {
    constructor(config = {}, inMap = new Map()) {
        Object.defineProperty(this, "core", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                sparkl: {
                    env: defaultConfig.env,
                    endpointType: defaultConfig.endpointType,
                },
                consoleLoggingEnabled: defaultConfig.consoleLoggingEnabled,
                httpLoggingEnabled: defaultConfig.httpLoggingEnabled,
                includeReportingLocation: defaultConfig.includeReportingLocation,
            }
        });
        Object.defineProperty(this, "token", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "index", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: INDEXES.CLAIMS
        });
        this.core = inMap;
        this.config = Object.assign(Object.assign({}, this.config), config);
    }
    info(messageId, message, ...args) {
        validateConfig(this.config);
        validateArgs(args);
        validateCore(this.core);
        logEvent(this, "info", messageId, message, ...args);
    }
    error(messageId, message, ...args) {
        validateConfig(this.config);
        validateArgs(args);
        validateCore(this.core);
        logEvent(this, "error", messageId, message, ...args);
    }
    warn(messageId, message, ...args) {
        validateConfig(this.config);
        validateArgs(args);
        validateCore(this.core);
        logEvent(this, "warn", messageId, message, ...args);
    }
    debug(messageId, message, ...args) {
        validateConfig(this.config);
        validateArgs(args);
        validateCore(this.core);
        logEvent(this, "debug", messageId, message, ...args);
    }
    setAuthToken(token) {
        this.token = token;
    }
    getAuthToken() {
        return this.token;
    }
    getContext() {
        return this.core;
    }
    setContext(...args) {
        setContext(this.core, ...args);
    }
    clearContext() {
        this.core.clear();
    }
}
//sets the core key values from a list of arguments
function setContext(core, ...args) {
    for (let index = 0; index < args.length - 1; index += 2) {
        let key = args[index];
        let value = args[index + 1];
        core.set(key, value);
    }
}
function updateHttpDestination(config) {
    var _a, _b;
    let env = ((_a = config.sparkl) === null || _a === void 0 ? void 0 : _a.env) || defaultConfig.env;
    let endpointType = ((_b = config.sparkl) === null || _b === void 0 ? void 0 : _b.endpointType) || defaultConfig.endpointType;
    if (env === ENV.TEST && endpointType === ENDPOINT_TYPE.PRIVATE) {
        return defaultConfig.http.destination.test.private.url;
    }
    if (env === ENV.TEST && endpointType === ENDPOINT_TYPE.PUBLIC) {
        return defaultConfig.http.destination.test.public.url;
    }
    if (env === ENV.PROD && endpointType === ENDPOINT_TYPE.PRIVATE) {
        return defaultConfig.http.destination.prod.private.url;
    }
    if (env === ENV.PROD && endpointType === ENDPOINT_TYPE.PUBLIC) {
        return defaultConfig.http.destination.prod.public.url;
    }
    return "";
}
//log the actual event to given destination (stdout or http).
function logEvent(logger, level, messageId, message, ...args) {
    const { core, config } = logger;
    let eventMap = getEventMap(core, level, messageId, message, ...args);
    if (config.includeReportingLocation) {
        eventMap = addLocation(eventMap);
    }
    if (config.httpLoggingEnabled) {
        writeToHTTP(logger, eventMap);
    }
    if (config.consoleLoggingEnabled) {
        writeToSTDOUT(eventMap);
    }
}
function writeToSTDOUT(inMap) {
    let json = mapToJsonString(inMap);
    switch (inMap.get("logLevel")) {
        case "info":
            console.info(json);
            break;
        case "warn":
            console.warn(json);
            break;
        case "error":
            console.error(json);
            break;
        case "debug":
            console.debug(json);
            break;
        default:
            console.log(json);
    }
}
function writeToHTTP(logger, inMap) {
    return __awaiter(this, void 0, void 0, function* () {
        const { token, config } = logger;
        let json = mapToJsonString(inMap);
        let httpDestination = updateHttpDestination(config);
        if (httpDestination !== "") {
            axios
                .post(httpDestination, json, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": defaultConfig.http.headers["Content-Type"],
                    Accept: defaultConfig.http.headers["Accept"],
                    Index: logger.index,
                },
            })
                .then((response) => {
                return response;
            })
                .catch((error) => {
                console.error("Error writing to HTTP: " + error);
            });
        }
        else {
            console.error("Error writing to HTTP: Sparkl endpoint could not be determined from config.");
        }
    });
}
//combine input parameters with core logger values into an event map.
function getEventMap(core, level, messageId, message, ...args) {
    let map1 = argsToMap(InternalKeys.LOG_LEVEL, level, InternalKeys.MESSAGE_ID, messageId, InternalKeys.MESSAGE, message);
    let map2 = argsToMap(...args);
    let m1m2 = getNewCombinedMap(map1, map2);
    let contextMap = contextArgsToMap(...args);
    let coreCombinedMap = getNewCombinedMap(core, contextMap);
    m1m2.set("context", coreCombinedMap);
    return m1m2;
}
//parse the stack to determine the file and line number this log request originated.
function addLocation(eventMap) {
    const err = new Error();
    let stack = err.stack || "";
    let stackArray = stack.split("(");
    let entry = stackArray[4];
    return parseEntryForLocation(entry, eventMap);
}
//parse the specific stack entry to add exact file name and line number to event map.
function parseEntryForLocation(entry, eventMap) {
    if (entry.includes(")")) {
        let itemArray = entry.split(")");
        let lineLocParts = itemArray[0].split(":");
        if (lineLocParts.length >= 3) {
            eventMap
                .get("context")
                .set(CoreKeys.REPORTING_FILE, getFile(lineLocParts.slice(0, -2).join(":")));
            eventMap
                .get("context")
                .set(CoreKeys.LINE_NUMBER, lineLocParts[lineLocParts.length - 2]);
        }
    }
    return eventMap;
}
//make a new map from the maps passed in
function getNewCombinedMap(inMap1, inMap2) {
    let newMap1 = new Map(inMap1);
    let newMap2 = new Map(inMap2);
    let combinedMap = new Map([...newMap1, ...newMap2]);
    return combinedMap;
}
// //make a new map including only keys from CoreKeys
function contextArgsToMap(...args) {
    let map = new Map();
    const coreKeys = Object.values(CoreKeys);
    for (let x = 0; x < args.length - 1; x += 2) {
        let key = args[x];
        if (!coreKeys.includes(key))
            continue;
        map.set(key, args[x + 1]);
    }
    return map;
}
// convert map to json string for testing
function mapToJsonString(inMap) {
    let obj = {};
    for (let x of inMap) {
        let key = x[0];
        let val = x[1];
        if (key === "context") {
            obj[key] = {};
            for (let y of val) {
                let contextKey = y[0];
                let contextVal = y[1];
                obj[key][contextKey] = contextVal;
            }
            continue;
        }
        obj[key] = val;
    }
    return obj;
}
function validateArgs(args) {
    if (args.length % 2 !== 0) {
        console.warn("Number of arguments passed to log function is not even, output may not be as expected.");
    }
}
function validateCore(core) {
    if (!core.has(CoreKeys.CORRELATION_ID)) {
        console.warn("CorrelationId is missing from context. Use setContext() to set it.");
    }
    if (!core.has(CoreKeys.APPLICATION_ID)) {
        console.warn("ApplicationId is missing from context. Use setContext() to set it.");
    }
}
function validateConfig(config) {
    var _a, _b, _c, _d;
    if (!config.httpLoggingEnabled) {
        return;
    }
    if (((_a = config.sparkl) === null || _a === void 0 ? void 0 : _a.env) !== ENV.TEST && ((_b = config.sparkl) === null || _b === void 0 ? void 0 : _b.env) !== ENV.PROD) {
        console.error("env is missing or invalid in config. Must be 'test' or 'prod'.");
    }
    if (((_c = config.sparkl) === null || _c === void 0 ? void 0 : _c.endpointType) !== ENDPOINT_TYPE.PRIVATE &&
        ((_d = config.sparkl) === null || _d === void 0 ? void 0 : _d.endpointType) !== ENDPOINT_TYPE.PUBLIC) {
        console.error("endpointType is missing or invalid in config. Must be 'private' or 'public'.");
    }
}
