export class InternalKeys {
}
Object.defineProperty(InternalKeys, "LOG_LEVEL", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "logLevel"
});
Object.defineProperty(InternalKeys, "MESSAGE", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "message"
});
Object.defineProperty(InternalKeys, "MESSAGE_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "messageId"
});
export class CoreKeys {
}
Object.defineProperty(CoreKeys, "ACCOUNT", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "account"
});
Object.defineProperty(CoreKeys, "APPLICATION_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "applicationId"
});
Object.defineProperty(CoreKeys, "CORRELATION_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "correlationId"
});
Object.defineProperty(CoreKeys, "LINE_NUMBER", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "lineNumber"
});
Object.defineProperty(CoreKeys, "LOCATION", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "location"
});
Object.defineProperty(CoreKeys, "REPORTING_FILE", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "reportingFile"
});
Object.defineProperty(CoreKeys, "REQUEST_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "requestId"
});
Object.defineProperty(CoreKeys, "TRACE_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "traceId"
});
Object.defineProperty(CoreKeys, "SPAN_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "spanId"
});
class Keys {
}
Object.defineProperty(Keys, "ACCOUNT", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "account"
});
Object.defineProperty(Keys, "APPLICATION_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "applicationId"
});
Object.defineProperty(Keys, "AREA", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "area"
});
Object.defineProperty(Keys, "CALLED_SERVICE", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "calledService"
});
Object.defineProperty(Keys, "CALLING_PROCESS", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "callingProcess"
});
Object.defineProperty(Keys, "CORRELATION_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "correlationId"
});
Object.defineProperty(Keys, "CONFIG_ITEM", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "configItem"
});
Object.defineProperty(Keys, "CLAIM_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "claimId"
});
Object.defineProperty(Keys, "CLAIM_NUM", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "claimNum"
});
Object.defineProperty(Keys, "CLIENT_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "clientId"
});
Object.defineProperty(Keys, "DEPLOYMENT_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "deploymentId"
});
Object.defineProperty(Keys, "ENTRYPOINT", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "entryPoint"
});
Object.defineProperty(Keys, "EVENT_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "eventId"
});
Object.defineProperty(Keys, "LOCATION", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "location"
});
Object.defineProperty(Keys, "REPO", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "repo"
});
Object.defineProperty(Keys, "RETURN_STATUS", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "returnStatus"
});
Object.defineProperty(Keys, "REQUEST_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "requestId"
});
Object.defineProperty(Keys, "SPAN_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "spanId"
});
Object.defineProperty(Keys, "TIMESTAMP", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "timestamp"
});
Object.defineProperty(Keys, "TRACE_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "traceId"
});
Object.defineProperty(Keys, "UA_BROWSER", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "ua-browser"
});
Object.defineProperty(Keys, "UA_DEVICE", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "ua-device"
});
Object.defineProperty(Keys, "UA_OS", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "ua-os"
});
Object.defineProperty(Keys, "USER_ID", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "userId"
});
Object.defineProperty(Keys, "VENDOR", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "vendor"
});
Object.defineProperty(Keys, "WORKGROUP", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "workGroup"
});
export const KEYS = Object.assign(Object.assign(Object.assign({}, InternalKeys), CoreKeys), Keys);
export var ENV;
(function (ENV) {
    ENV["TEST"] = "test";
    ENV["PROD"] = "prod";
})(ENV || (ENV = {}));
export var ENDPOINT_TYPE;
(function (ENDPOINT_TYPE) {
    ENDPOINT_TYPE["PRIVATE"] = "private";
    ENDPOINT_TYPE["PUBLIC"] = "public";
})(ENDPOINT_TYPE || (ENDPOINT_TYPE = {}));
export class INDEXES {
}
Object.defineProperty(INDEXES, "CLAIMS", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: "pc-claims"
});
