{
  "http": {
    "destination": {
      "test": {
        "private": {
          "url": "https://sparkl.claims.test.ic1.statefarm/v1/logs"
        },
        "public": {
          "url": "https://sparkl.claims.test.c1.statefarm/v1/logs"
        }
      },
      "prod": {
        "private": {
          "url": "https://sparkl.claims.ic1.statefarm/v1/logs"
        },
        "public": {
          "url": "https://sparkl.claims.c1.statefarm/v1/logs"
        }
      }
    },
    "method": "POST",
    "headers": {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  },
  "env": "",
  "endpointType": "",
  "consoleLoggingEnabled": true,
  "httpLoggingEnabled": false,
  "includeReportingLocation": true
}
