import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoggingService, LogLevel } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { BehaviorSubject, catchError, map, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environments/environment';
import { ContractMetadata, GetUploadIdResponse, SendContractMetadataResponse } from '../shared/models/api.model';
import { Contract } from '../shared/models/contract.model';
import { CorrelationIdService } from './correlation-id.service';

@Injectable()
export class UploadService {
  private currentPercent = 0;
  private errorCallback: (error: any) => void | undefined;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private correlationId: CorrelationIdService,
    private logger: LoggingService,
    private router: Router
  ) {}

  start(
    contract: Contract,
    updateCallback: (percentComplete: number) => void,
    doneCallback: (contract: Contract) => void,
    errorCallback: (error: any) => void
  ) {
    this.logger.sendSparklLog({
      messageId: 'COVA_UPLOAD_SERVICE_START',
      message: 'Start upload service',
      logLevel: LogLevel.INFO,
      reportingFile: 'upload.service.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.errorCallback = errorCallback;
    const uploadId = localStorage.getItem('uploadId');
    if (!uploadId) {
      this.logger.sendSparklLog({
        messageId: 'COVA_UPLOAD_SERVICE_UPLOAD_ID_NOT_FOUND_ERROR',
        message: `No upload id found for upload`,
        logLevel: LogLevel.ERROR,
        reportingFile: 'upload.service.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      this.router.navigate(['error-unresolvable'], { queryParamsHandling: 'merge' });
      return;
    }
    this.currentPercent += 10;
    updateCallback(this.currentPercent);
    const imageIdMap = new Map<string, File>();
    const contractMetaData: ContractMetadata = {
      contractId: contract.id,
      contractor: contract.nameOfContractor,
      contractorPhone: contract.contractorsPhoneNumber,
      contractDateSigned: contract.contractSignedDate,
      repairsSameAsEstimate:
        contract.differenceBetweenEstimateAndContract === 'Yes'
          ? 'true'
          : contract.differenceBetweenEstimateAndContract === 'No'
          ? 'false'
          : 'undefined',
      repairsCompleteOrSubstantiallyUnderway:
        contract.repairsSubstantiallyUnderway === 'Yes'
          ? 'true'
          : contract.repairsSubstantiallyUnderway === 'No'
          ? 'false'
          : 'undefined',
      contractTotalAmount: contract.totalAmountOfContract.toString(),
      images: contract.photosOfContract.map((image) => {
        const imageId = uuidv4();
        const imageUpload = {
          imageId: imageId,
          originalFileName: image.name,
          contentType: image.type,
        };
        imageIdMap.set(imageId, image);
        return imageUpload;
      }),
    };

    this.sendContractMetadata(uploadId, contractMetaData).subscribe((imageUrls) => {
      this.currentPercent += 10;
      updateCallback(this.currentPercent);
      const percentIncPerImage = Math.round((1 / imageUrls.length) * 100);
      const ps = new BehaviorSubject(0);

      ps.subscribe((imageNum) => {
        if (imageNum === imageUrls.length) {
          this.currentPercent = 100;
          updateCallback(100);
          setTimeout(() => {
            doneCallback(contract);
          }, 1000);
          return;
        }
        const iurl = imageUrls[imageNum];
        let imageFile = imageIdMap.get(iurl.imageId);
        if (!imageFile) {
          this.logger.sendSparklLog({
            messageId: 'COVA_UPLOAD_SERVICE_NO_IMAGE_ID_FOUND_ERROR',
            message: 'Could not find image file with imageId',
            logLevel: LogLevel.ERROR,
            reportingFile: 'upload.service.ts',
            contractId: contractMetaData.contractId,
            uploadId: uploadId,
            calledService: '',
            httpResponse: '',
            httpStatus: '',
            contractor: '',
            contractDateSigned: '',
            numberOfPhotos: '',
            imageId: iurl.imageId,
            fileType: '',
            fileName: '',
            fileSize: '',
          });
          imageFile = contract.photosOfContract[imageNum];
        }

        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_IMAGE_START',
          message: 'Upload contract image start',
          logLevel: LogLevel.INFO,
          reportingFile: 'upload.service.ts',
          contractId: contractMetaData.contractId,
          uploadId: uploadId,
          calledService: '',
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: iurl.imageId,
          fileType: imageFile.type,
          fileName: imageFile.name,
          fileSize: imageFile.size.toString(),
        });
        this.sendContractImage(imageFile, iurl.uploadUrl).subscribe(() => {
          this.currentPercent += percentIncPerImage;
          updateCallback(this.currentPercent);
          setTimeout(() => {
            ps.next(imageNum + 1);
          }, 1000);
        });
      });
    });
  }

  getUploadId2(extClaimId: string, extClientId: string, roleType: string) {
    const body = {
      externalClaimId: extClaimId,
      externalClientId: extClientId,
      participantRole: roleType,
      coverageType: 'A',
    };
    const url = `${environment.api}`;

    return new Promise<boolean>((resolve, reject) =>
      this.http.post<GetUploadIdResponse>(url, body).subscribe(
        (res) => {
          const uploadId = res.payload.uploadId;
          localStorage.setItem('uploadId', `${uploadId}`);
          this.logger.sendSparklLog({
            messageId: 'COVA_UPLOAD_ID_RETRIEVAL_SUCCESS',
            message: 'Upload ID retrieval succeeded',
            logLevel: LogLevel.INFO,
            reportingFile: 'upload.service.ts',
            contractId: '',
            uploadId: uploadId,
            calledService: url,
            httpResponse: res.serviceStatus ? res.serviceStatus.status.toString() : '',
            httpStatus: '',
            contractor: '',
            contractDateSigned: '',
            numberOfPhotos: '',
            imageId: '',
            fileType: '',
            fileName: '',
            fileSize: '',
          });
          resolve(!!uploadId);
        },
        (err) => {
          localStorage.setItem('uploadId', '');
          this.logger.sendSparklLog({
            messageId: 'COVA_UPLOAD_ID_RETRIEVAL_FAIL',
            message: `Upload ID retrieval failed message: ${err.message}`,
            logLevel: LogLevel.ERROR,
            reportingFile: 'upload.service.ts',
            contractId: '',
            uploadId: '',
            calledService: url,
            httpResponse: err?.status.toString() ?? 'no status code',
            httpStatus: err?.statusText ?? 'no status text',
            contractor: '',
            contractDateSigned: '',
            numberOfPhotos: '',
            imageId: '',
            fileType: '',
            fileName: '',
            fileSize: '',
          });
          this.router.navigate(['error-unresolvable'], { queryParamsHandling: 'merge' });
          reject(null);
        }
      )
    );
  }

  sendContractMetadata(uploadId: string, metadata: ContractMetadata) {
    const correlationId = this.correlationId.get();
    const url = `${environment.api}/${uploadId}/contract`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };
    const body = JSON.stringify(metadata);
    this.logger.sendSparklLog({
      messageId: 'COVA_UPLOAD_SERVICE_METADATA_START',
      message: 'Upload contract metadata start',
      logLevel: LogLevel.INFO,
      reportingFile: 'upload.service.ts',
      contractId: metadata.contractId,
      uploadId: uploadId,
      calledService: url,
      httpResponse: '',
      httpStatus: '',
      contractor: metadata.contractor,
      contractDateSigned: metadata.contractDateSigned,
      numberOfPhotos: metadata.images.length.toString(),
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });

    return this.http.post<SendContractMetadataResponse>(url, body, httpOptions).pipe(
      catchError((err) => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_METADATA_ERROR',
          message: 'Contract metadata error',
          logLevel: LogLevel.ERROR,
          reportingFile: 'upload.service.ts',
          contractId: metadata.contractId,
          uploadId: uploadId,
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        this.errorCallback(err);
        throw new Error(`Received error sending contract metadata: ${err.status.toString()}`);
      }),
      map((response) => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_METADATA_SUCCESS',
          message: 'Contract metadata sent',
          logLevel: LogLevel.INFO,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        return response.payload.uploadURLs;
      })
    );
  }

  sendContractImage(imageFile: File, s3Url: string) {
    const url = s3Url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': imageFile.type,
      }),
    };
    return this.http.put(url, imageFile, httpOptions).pipe(
      catchError((err) => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_IMAGE_ERROR',
          message: 'Contract image error',
          logLevel: LogLevel.ERROR,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        this.sendErrorMessage()!.subscribe(() => {
          this.errorCallback(err);
        });
        throw new Error(`Received error sending contract image: ${err.status.toString()}`);
      }),
      map(() => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_IMAGE_SUCCESS',
          message: 'Contract image sent',
          logLevel: LogLevel.INFO,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        return true;
      })
    );
  }

  sendCompleteMessage() {
    if (!localStorage.getItem('uploadId')) {
      this.logger.sendSparklLog({
        messageId: 'COVA_UPLOAD_SKIP_COMPLETE_MESSAGE',
        message: 'Not sending complete message because no uploadId',
        logLevel: LogLevel.INFO,
        reportingFile: 'upload.service.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      return;
    }
    this.logger.sendSparklLog({
      messageId: 'COVA_UPLOAD_COMPLETE_MESSAGE_START',
      message: 'Upload complete message start',
      logLevel: LogLevel.INFO,
      reportingFile: 'upload.service.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    const correlationId = this.correlationId.get();
    const url = `${environment.api}/${localStorage.getItem('uploadId')}`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };
    const body = JSON.stringify({ uploadState: 'UploadComplete' });

    return this.http.patch(url, body, httpOptions).pipe(
      catchError((err) => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_COMPLETE_MESSAGE_ERROR',
          message: 'Complete message error',
          logLevel: LogLevel.ERROR,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        localStorage.setItem('uploadId', '');
        throw new Error(`Received error sending complete message ${err.status.toString()}`);
      }),
      tap(() => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_COMPLETE_MESSAGE_SENT',
          message: 'Complete message sent',
          logLevel: LogLevel.INFO,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        localStorage.setItem('uploadId', '');
      })
    );
  }

  sendErrorMessage() {
    if (!localStorage.getItem('uploadId')) {
      this.logger.sendSparklLog({
        messageId: 'COVA_UPLOAD_SERVICE_SKIP_ERROR_MESSAGE',
        message: 'Not ending error message because no uploadId',
        logLevel: LogLevel.INFO,
        reportingFile: 'upload.service.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      return;
    }
    this.logger.sendSparklLog({
      messageId: 'COVA_UPLOAD_SERVICE_ERROR_MESSAGE_START',
      message: 'Upload error message start',
      logLevel: LogLevel.INFO,
      reportingFile: 'upload.service.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    const correlationId = this.correlationId.get();
    const url = `${environment.api}/${localStorage.getItem('uploadId')}`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };
    const body = JSON.stringify({ uploadState: 'ERROR' });

    return this.http.patch(url, body, httpOptions).pipe(
      catchError((err) => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_ERROR_MESSAGE_ERROR',
          message: 'Error message error',
          logLevel: LogLevel.ERROR,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        localStorage.setItem('uploadId', '');
        throw new Error(`Received error sending error message: ${err.status.toString()}`);
      }),
      tap(() => {
        this.logger.sendSparklLog({
          messageId: 'COVA_UPLOAD_SERVICE_ERROR_MESSAGE_SUCCESS',
          message: 'Error message sent',
          logLevel: LogLevel.INFO,
          reportingFile: 'upload.service.ts',
          contractId: '',
          uploadId: '',
          calledService: url,
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        localStorage.setItem('uploadId', '');
      })
    );
  }
}
