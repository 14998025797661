//*--------------------------------------------------------------------------------------------------
//*  Map usage: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
//*--------------------------------------------------------------------------------------------------
import defaultConfig from "./config.json";
import { INDEXES } from "./constants";
import { setContext, logEvent } from "./util";
import { validateConfig, validateArgs, validateCore, validateHTMLCharacters } from "./validate";
export class Logger {
    core;
    config = {
        sparkl: {
            env: defaultConfig.env,
            endpointType: defaultConfig.endpointType
        },
        consoleLoggingEnabled: defaultConfig.consoleLoggingEnabled,
        httpLoggingEnabled: defaultConfig.httpLoggingEnabled,
        includeReportingLocation: defaultConfig.includeReportingLocation
    };
    token = "";
    index = INDEXES.CLAIMS;
    constructor(config = {}, inMap = new Map()){
        this.core = inMap;
        this.config = {
            ...this.config,
            ...config
        };
        setHttpForEnvironment(this.config);
    }
    log(logLevel, messageId, message, ...args) {
        validateConfig(this.config);
        validateArgs(args);
        validateCore(this.core);
        if (this.config.httpLoggingEnabled) {
            validateHTMLCharacters(this.core, messageId, message, args);
        }
        try {
            logEvent(this, logLevel, messageId, message, ...args);
        } catch (e) {
            console.error(e);
        }
    }
    info(messageId, message, ...args) {
        this.log("info", messageId, message, ...args);
    }
    error(messageId, message, ...args) {
        this.log("error", messageId, message, ...args);
    }
    warn(messageId, message, ...args) {
        this.log("warn", messageId, message, ...args);
    }
    debug(messageId, message, ...args) {
        this.log("debug", messageId, message, ...args);
    }
    setAuthToken(token) {
        this.token = token;
    }
    getAuthToken() {
        return this.token;
    }
    getContext() {
        return this.core;
    }
    setContext(...args) {
        setContext(this.core, ...args);
    }
    clearContext() {
        this.core.clear();
    }
}
/** 
 *  Function - Automatically set both httpLoggingEnabled and consoleLoggingEnabled based
 *             on whether running in a browser or on a server.  Only allow http logging
 *             for front end user interfaces, SPA's.
 */ function setHttpForEnvironment(config) {
    let currentSetting = config.httpLoggingEnabled;
    config.httpLoggingEnabled = true;
    if (typeof window === 'undefined') {
        config.httpLoggingEnabled = false;
        config.consoleLoggingEnabled = true;
    }
    if (currentSetting !== null && currentSetting !== config.httpLoggingEnabled) {
        console.warn("Configuration setting for HTTP Logging is not available for this environment, logging will use the required setting for the environment.");
    }
}
