import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'rapid-reimbursement-ui-cov-a/src/app/app.state';
import { deleteAllContracts } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.action';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-cancel-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.css'],
})
export class CancelModalComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() id: string;
  screenId = 'confirm-cancel-modal';

  constructor(
    public element: ElementRef,
    public router: Router,
    public ngZone: NgZone,
    public store: Store<AppState>,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: localStorage.getItem('extClaimId')!,
      externalClientId: localStorage.getItem('extClientId')!,
      roleType: localStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId
    });
  }
  ngAfterViewInit() {
    window.oneX.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.element.nativeElement);
  }

  goToLanding() {
    this.closeCancelModal();
    this.store.dispatch(deleteAllContracts());
    this.ngZone.run(() => {
      this.router.navigate([localStorage.getItem('baseUrl')], { queryParamsHandling: 'merge' });
    });
  }

  closeCancelModal() {
    window.oneX.Modal.hideModal();
  }
}
