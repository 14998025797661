import { CommonModule } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'rapid-reimbursement-ui-cov-a/src/app/app.state';
import { updateContract } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.action';
import { selectUnsubmittedContracts } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.selector';
import { LoggingService, LogLevel } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { UploadService } from 'rapid-reimbursement-ui-cov-a/src/services/upload.service';
import { Contract, CONTRACT_COPY } from 'rapid-reimbursement-ui-cov-a/src/shared/models/contract.model';
import { BehaviorSubject, ReplaySubject, take, takeUntil, tap } from 'rxjs';
import { CorrelationIdService } from '../../services/correlation-id.service';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [CommonModule, RouterModule],
  providers: [UploadService, CorrelationIdService],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  contractsToUpload: Contract[];
  addAnotherAfterUpload: boolean;
  currentPercent = new BehaviorSubject(0);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    public upload: UploadService,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.logger.sendSparklLog({
      messageId: 'COVA_UPLOAD_PAGE',
      message: 'Upload page',
      logLevel: LogLevel.INFO,
      reportingFile: 'upload.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: localStorage.getItem('extClaimId')!,
      externalClientId: localStorage.getItem('extClientId')!,
      roleType: localStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: 'progress-wheel',
    });

    const $loader = window.oneX.$('#uploadProgess');
    window.oneX.ProgressiveLoader.updatePercent($loader, 1);
    this.addAnotherAfterUpload = this.route.snapshot.queryParamMap.get('addAnother') === 'true';
    this.store
      .select(selectUnsubmittedContracts)
      .pipe(
        take(1),
        tap((contracts) => {
          this.contractsToUpload = contracts;
          if (contracts.length > 0) {
            this.logger.sendSparklLog({
              messageId: 'COVA_UPLOAD_START',
              message: 'Upload started',
              logLevel: LogLevel.INFO,
              reportingFile: 'upload.component.ts',
              contractId: '',
              uploadId: '',
              calledService: '',
              httpResponse: '',
              httpStatus: '',
              contractor: '',
              contractDateSigned: '',
              numberOfPhotos: '',
              imageId: '',
              fileType: '',
              fileName: '',
              fileSize: '',
            });
            this.upload.start(
              contracts[0],
              (percentComplete) => {
                this.currentPercent.next(percentComplete);
              },
              (contract: Contract) => {
                const newContract = CONTRACT_COPY(contract);
                newContract.submitted = true;
                newContract.dateOfSubmission = new Date().toLocaleDateString('en-US');
                this.store.dispatch(updateContract({ contract: newContract }));
                if (this.addAnotherAfterUpload) {
                  this.logger.sendSparklLog({
                    messageId: 'COVA_UPLOAD_ADD_ANOTHER_GO_TO_CONTRACT',
                    message: 'Go to contract',
                    logLevel: LogLevel.INFO,
                    reportingFile: 'upload.component.ts',
                    contractId: '',
                    uploadId: '',
                    calledService: '',
                    httpResponse: '',
                    httpStatus: '',
                    contractor: '',
                    contractDateSigned: '',
                    numberOfPhotos: '',
                    imageId: '',
                    fileType: '',
                    fileName: '',
                    fileSize: '',
                  });
                  this.ngZone.run(() => {
                    this.router.navigate([`${localStorage.getItem('baseUrl')}/contract`], {
                      queryParams: { addAnother: null },
                      queryParamsHandling: 'merge',
                    });
                  });
                } else {
                  const sendCompleteMessage = this.upload.sendCompleteMessage();
                  if (sendCompleteMessage) {
                    sendCompleteMessage.subscribe(() => {
                      this.ngZone.run(() => {
                        this.router.navigate([`${localStorage.getItem('baseUrl')}/confirmation`], {
                          queryParams: { addAnother: null },
                          queryParamsHandling: 'merge',
                        });
                      });
                    });
                  } else {
                    this.logger.sendSparklLog({
                      messageId: 'COVA_UPLOAD_NO_ADD_ANOTHER_GO_TO_CONFIRMATION',
                      message: 'Go to confirmation',
                      logLevel: LogLevel.INFO,
                      reportingFile: 'upload.component.ts',
                      contractId: '',
                      uploadId: '',
                      calledService: '',
                      httpResponse: '',
                      httpStatus: '',
                      contractor: '',
                      contractDateSigned: '',
                      numberOfPhotos: '',
                      imageId: '',
                      fileType: '',
                      fileName: '',
                      fileSize: '',
                    });
                    this.ngZone.run(() => {
                      this.router.navigate([`${localStorage.getItem('baseUrl')}/confirmation`], {
                        queryParams: { addAnother: null },
                        queryParamsHandling: 'merge',
                      });
                    });
                  }
                }
              },
              (error: any) => {
                const sendErrorMessage = this.upload.sendErrorMessage();
                if (sendErrorMessage) {
                  sendErrorMessage.subscribe(() => {
                    this.logger.sendSparklLog({
                      messageId: 'COVA_UPLOAD_UNRESOLVABLE_ERROR_MESSAGE_SENT',
                      message: 'Unresolvable error message sent',
                      logLevel: LogLevel.ERROR,
                      reportingFile: 'upload.component.ts',
                      contractId: '',
                      uploadId: '',
                      calledService: '',
                      httpResponse: '',
                      httpStatus: '',
                      contractor: '',
                      contractDateSigned: '',
                      numberOfPhotos: '',
                      imageId: '',
                      fileType: '',
                      fileName: '',
                      fileSize: '',
                    });
                    this.ngZone.run(() => {
                      this.router.navigate(['/error-unresolvable'], {
                        queryParams: { addAnother: null },
                        queryParamsHandling: 'merge',
                      });
                    });
                  });
                } else {
                  this.logger.sendSparklLog({
                    messageId: 'COVA_UPLOAD_UNRESOLVABLE_ERROR_NO_MESSAGE_SENT',
                    message: 'Unresolvable error',
                    logLevel: LogLevel.ERROR,
                    reportingFile: 'upload.component.ts',
                    contractId: '',
                    uploadId: '',
                    calledService: '',
                    httpResponse: '',
                    httpStatus: '',
                    contractor: '',
                    contractDateSigned: '',
                    numberOfPhotos: '',
                    imageId: '',
                    fileType: '',
                    fileName: '',
                    fileSize: '',
                  });
                  this.ngZone.run(() => {
                    this.router.navigate(['/error-unresolvable'], {
                      queryParams: { addAnother: null },
                      queryParamsHandling: 'merge',
                    });
                  });
                }
              }
            );
          } else if (this.addAnotherAfterUpload) {
            this.logger.sendSparklLog({
              messageId: 'COVA_NO_UPLOAD_ADD_ANOTHER_GO_TO_CONTRACT',
              message: 'Go to contract',
              logLevel: LogLevel.INFO,
              reportingFile: 'upload.component.ts',
              contractId: '',
              uploadId: '',
              calledService: '',
              httpResponse: '',
              httpStatus: '',
              contractor: '',
              contractDateSigned: '',
              numberOfPhotos: '',
              imageId: '',
              fileType: '',
              fileName: '',
              fileSize: '',
            });
            this.ngZone.run(() => {
              this.router.navigate([`${localStorage.getItem('baseUrl')}/contract`], {
                queryParams: { addAnother: null },
                queryParamsHandling: 'merge',
              });
            });
          } else {
            this.logger.sendSparklLog({
              messageId: 'COVA_NO_UPLOAD_NO_ADD_ANOTHER_GO_TO_CONFIRMATION',
              message: 'Go to confirmation',
              logLevel: LogLevel.INFO,
              reportingFile: 'upload.component.ts',
              contractId: '',
              uploadId: '',
              calledService: '',
              httpResponse: '',
              httpStatus: '',
              contractor: '',
              contractDateSigned: '',
              numberOfPhotos: '',
              imageId: '',
              fileType: '',
              fileName: '',
              fileSize: '',
            });
            this.ngZone.run(() => {
              this.router.navigate([`${localStorage.getItem('baseUrl')}/confirmation`], {
                queryParams: { addAnother: null },
                queryParamsHandling: 'merge',
              });
            });
          }
        })
      )
      .subscribe();
    this.currentPercent.pipe(takeUntil(this.destroyed$)).subscribe((nextPercentage) => {
      window.oneX.ProgressiveLoader.updatePercent($loader, nextPercentage);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
