export const environment = {
  production: false,
  api_auth: 'https://auth-utl.claimsvc.test.c1.statefarm/claimutility-api-authentication/v1',
  claims_hub: 'https://hub.claims.test.statefarm.com/#',
  api: 'https://rapidapi.claimsvc.test.c1.statefarm/v2/upload',
  api_domain: 'rapidapi.claimsvc.test.c1.statefarm',
  splunk: {
    env: 'test',
    api: 'ee2AS1YCVY8okhCXKz1lI6qqJyaCIB2f932rtsjm',
  },
};
