import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoggingService, LogLevel } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-malformed-request-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './malformed-request-error.component.html',
  styleUrl: './malformed-request-error.component.css',
})
export class MalformedRequestErrorComponent implements OnInit {
  screenId = 'error-connection';
  constructor(private logger: LoggingService, private analytics: AnalyticsService) {}

  ngOnInit() {
    this.logger.sendSparklLog({
      messageId: 'COVA_MALFORMED_REQUEST_ERROR_PAGE',
      message: 'Malformed request error',
      logLevel: LogLevel.ERROR,
      reportingFile: 'malformed-request-error.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: localStorage.getItem('extClaimId')!,
      externalClientId: localStorage.getItem('extClientId')!,
      roleType: localStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }
}
