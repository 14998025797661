export class InternalKeys {
    static LOG_LEVEL = "logLevel";
    static MESSAGE = "message";
    static MESSAGE_ID = "messageId";
}
export class CoreKeys {
    static ACCOUNT = "account";
    static APPLICATION_ID = "applicationId";
    static CORRELATION_ID = "correlationId";
    static LINE_NUMBER = "lineNumber";
    static LOCATION = "location";
    static REPORTING_FILE = "reportingFile";
    static REQUEST_ID = "requestId";
    static TRACE_ID = "traceId";
    static SPAN_ID = "spanId";
}
class Keys {
    static ACCOUNT = "account";
    static APPLICATION_ID = "applicationId";
    static AREA = "area";
    static CALLED_SERVICE = "calledService";
    static CALLING_PROCESS = "callingProcess";
    static CORRELATION_ID = "correlationId";
    static CONFIG_ITEM = "configItem";
    static CLAIM_ID = "claimId";
    static CLAIM_NUM = "claimNum";
    static CLIENT_ID = "clientId";
    static DEPLOYMENT_ID = "deploymentId";
    static ENTRYPOINT = "entryPoint";
    static EVENT_ID = "eventId";
    static LOCATION = "location";
    static REPO = "repo";
    static RETURN_STATUS = "returnStatus";
    static REQUEST_ID = "requestId";
    static SPAN_ID = "spanId";
    static TIMESTAMP = "timestamp";
    static TRACE_ID = "traceId";
    static UA_BROWSER = "ua-browser";
    static UA_DEVICE = "ua-device";
    static UA_OS = "ua-os";
    static USER_ID = "userId";
    static VENDOR = "vendor";
    static WORKGROUP = "workGroup";
}
export const KEYS = {
    ...InternalKeys,
    ...CoreKeys,
    ...Keys
};
export var ENV;
(function(ENV) {
    ENV["TEST"] = "test";
    ENV["PROD"] = "prod";
})(ENV || (ENV = {}));
export var ENDPOINT_TYPE;
(function(ENDPOINT_TYPE) {
    ENDPOINT_TYPE["PRIVATE"] = "private";
    ENDPOINT_TYPE["PUBLIC"] = "public";
})(ENDPOINT_TYPE || (ENDPOINT_TYPE = {}));
export class INDEXES {
    static CLAIMS = "pc-claims";
}
