import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService, LogLevel } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  extClaimId: string;
  extClientId: string;
  roleType: string;
  sessionTimeout: string | null = null;
  screenId: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    const extClaimId = this.route.snapshot.paramMap.get('extClaimId');
    const extClientId = this.route.snapshot.paramMap.get('extClientId');
    const roleType = this.route.snapshot.paramMap.get('roleType');
    const baseUrl = `/claim/${extClaimId}/client/${extClientId}/role/${roleType}`;
    localStorage.setItem('extClaimId', extClaimId ?? 'no-extClaimId');
    localStorage.setItem('extClientId', extClientId ?? 'no-extClientId');
    localStorage.setItem('roleType', roleType ?? 'no-roleType');
    localStorage.setItem('baseUrl', baseUrl);
    let correlationId = sessionStorage.getItem('CORRELATION_ID');
    if (!correlationId) {
      correlationId = uuidv4();
      sessionStorage.setItem('CORRELATION_ID', correlationId);
    }
    this.logger.sendSparklLog({
      messageId: 'COVA_START_APP',
      message: 'Start app',
      logLevel: LogLevel.INFO,
      reportingFile: 'landing.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.screenId = 'home';
    if (!extClaimId || !extClientId || !roleType) {
      this.logger.sendSparklLog({
        messageId: 'COVA_LANDING_MALFORMED_REQUEST',
        message: 'Malformed request',
        logLevel: LogLevel.ERROR,
        reportingFile: 'landing.component.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      this.ngZone.run(() => {
        this.router.navigate(['error-malformed-request'], { queryParamsHandling: 'merge' });
        this.analytics.setAnalyticsDataAngular({
          screenChannel: `structural-rapid-reimburse`,
          externalClaimId: localStorage.getItem('extClaimId')!,
          externalClientId: localStorage.getItem('extClientId')!,
          roleType: localStorage.getItem('roleType')!,
          authentication: 'partial',
          screenId: 'error-malformed-request',
        });
      });
    }
    this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe((next) => {
      if (next.has('sessionTimeout')) {
        setTimeout(() => {
          this.logger.sendSparklLog({
            messageId: 'COVA_SESSION_TIMEOUT',
            message: 'Session timeout',
            logLevel: LogLevel.INFO,
            reportingFile: 'landing.component.ts',
            contractId: '',
            uploadId: '',
            calledService: '',
            httpResponse: '',
            httpStatus: '',
            contractor: '',
            contractDateSigned: '',
            numberOfPhotos: '',
            imageId: '',
            fileType: '',
            fileName: '',
            fileSize: '',
          });
          this.analytics.setAnalyticsDataAngular({
            screenChannel: `structural-rapid-reimburse`,
            externalClaimId: localStorage.getItem('extClaimId')!,
            externalClientId: localStorage.getItem('extClientId')!,
            roleType: localStorage.getItem('roleType')!,
            authentication: 'partial',
            screenId: 'session-expired',
          });
          window.oneX.Modal.showModal(this, 'sessionTimeoutModal');
        }, 1000);
      }
      this.logger.sendSparklLog({
        messageId: 'COVA_SET_ANALYTICS_DATA',
        message: 'Set analytics data',
        logLevel: LogLevel.INFO,
        reportingFile: 'landing.component.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      this.analytics.setAnalyticsDataAngular({
        screenChannel: `structural-rapid-reimburse`,
        externalClaimId: localStorage.getItem('extClaimId')!,
        externalClientId: localStorage.getItem('extClientId')!,
        roleType: localStorage.getItem('roleType')!,
        authentication: 'partial',
        screenId: this.screenId,
      });
    });
  }

  goToEnterContract(): void {
    this.logger.sendSparklLog({
      messageId: 'COVA_LANDING_GO_TO_CONTRACT',
      message: 'Go to contract',
      logLevel: LogLevel.INFO,
      reportingFile: 'landing.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.ngZone.run(() => {
      this.router.navigate([`${localStorage.getItem('baseUrl')}/contract`], { queryParamsHandling: 'merge' });
    });
  }

  hideSessionTimeoutModal() {
    this.logger.sendSparklLog({
      messageId: 'COVA_CLOSE_TIMEOUT_MODAL',
      message: 'Close timeout modal',
      logLevel: LogLevel.INFO,
      reportingFile: 'landing.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.logger.sendSparklLog({
      messageId: 'COVA_TIMEOUT_MODAL_GO_TO_LANDING',
      message: 'Go to landing',
      logLevel: LogLevel.INFO,
      reportingFile: 'landing.component.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    this.ngZone.run(() => {
      this.router.navigate([localStorage.getItem('baseUrl')], {
        queryParams: { sessionTimeout: null },
        queryParamsHandling: 'merge',
      });
    });
    window.oneX.Modal.hideModal();
  }
}
