//make a new map using a random array of args passed in
export function argsToMap(...args) {
    let map = new Map();
    for (let x = 0; x < args.length - 1; x += 2) {
        let key = args[x];
        map.set(key, args[x + 1]);
    }
    return map;
}
//parse file path for file name
export function getFile(longFile) {
    let fileParts = longFile.split(/[\\/]/);
    return fileParts[fileParts.length - 1];
}
