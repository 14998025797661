import { CommonModule } from '@angular/common';
import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-session-timeout-modal',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.css'],
})
export class SessionTimeoutModalComponent implements OnInit {
  id = 'warningTimeoutModal';
  screenId = 'session-expiring';
  extClaimId: string | null = null;
  extClientId: string | null = null;
  roleType: string | null = null;

  WARNING_TIMEOUT = 600;
  SESSION_TIMEOUT = 120;

  warningTimeout: any;
  sessionTimeout: any;

  countdown: number | any;
  countdownTimer: any;

  warningUp = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.timeout();

    this.extClaimId = this.route.snapshot.paramMap.get('extClaimId');
    this.extClientId = this.route.snapshot.paramMap.get('extClientId');
    this.roleType = this.route.snapshot.paramMap.get('roleType');
  }

  @HostListener('document:click')
  @HostListener('document:wheel')
  @HostListener('document:keypress')
  @HostListener('document:touchstart')
  @HostListener('document:touchmove')
  @HostListener('document:touchend')
  timeout() {
    if (!this.warningUp && !window.location.href.endsWith('?sessionTimeout=true')) {
      clearTimeout(this.warningTimeout);
      clearInterval(this.countdownTimer);
      this.warningTimeout = setTimeout(() => this.showWarningTimeoutModal(), this.WARNING_TIMEOUT * 1000);
    }
  }

  showWarningTimeoutModal() {
    window.oneX.Modal.showModal(this, this.id);
    this.warningUp = true;
    this.sessionTimeout = setTimeout(() => this.timeoutSession(), this.SESSION_TIMEOUT * 1000);
    this.startCountdown();
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: localStorage.getItem('extClaimId')!,
      externalClientId: localStorage.getItem('extClientId')!,
      roleType: localStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }

  extendSession() {
    clearTimeout(this.sessionTimeout);
    clearInterval(this.countdownTimer);
    window.oneX.Modal.hideModal();
    this.warningUp = false;
    this.timeout();
  }

  expireSession() {
    window.oneX.Modal.hideModal();
    clearTimeout(this.sessionTimeout);
    clearInterval(this.countdownTimer);
    this.warningUp = false;
    this.ngZone.run(() => {
      this.router.navigate([localStorage.getItem('baseUrl')], {
        queryParams: { sessionTimeout: 'true' },
        queryParamsHandling: 'merge',
      });
    });
  }

  timeoutSession() {
    this.expireSession();
  }

  startCountdown() {
    this.countdown = this.SESSION_TIMEOUT;
    this.countdownTimer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      }
    }, 1000);
  }
}
