import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { LogLevel, LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { Observable, catchError, tap } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthLocalService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private http = inject(HttpClient);

  constructor(private route: ActivatedRoute, private router: Router, private logger: LoggingService) {}

  getToken(extClaimId: string, extClientId: string) {
    return new Promise<string | null>((resolve, reject) => {
      if (!this.isValidExtClaimId(extClaimId) || !this.isValidExtClientId(extClientId)) {
        this.logger.sendSparklLog({
          messageId: 'COVA_AUTH_MALFORMED_REQUEST_ERROR',
          message: `Malformed request - extClaimId: ${extClaimId} | extClientId: ${extClientId}`,
          logLevel: LogLevel.ERROR,
          reportingFile: 'authlocal.service.ts',
          contractId: '',
          uploadId: '',
          calledService: '',
          httpResponse: '',
          httpStatus: '',
          contractor: '',
          contractDateSigned: '',
          numberOfPhotos: '',
          imageId: '',
          fileType: '',
          fileName: '',
          fileSize: '',
        });
        this.router.navigate(['error-malformed-request'], { queryParamsHandling: 'merge' });
        resolve(null);
        return;
      }
      this.logger.sendSparklLog({
        messageId: 'COVA_AUTH_START',
        message: `Auth start`,
        logLevel: LogLevel.INFO,
        reportingFile: 'authlocal.service.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      if (this.isTokenValid(extClaimId, extClientId)) {
        resolve(`${localStorage.getItem(this.JWT_TOKEN)}`);
        return;
      }
      this.logger.sendSparklLog({
        messageId: 'COVA_AUTH_TOKEN_RETRIEVAL_START',
        message: `Auth token retrieval start`,
        logLevel: LogLevel.INFO,
        reportingFile: 'authlocal.service.ts',
        contractId: '',
        uploadId: '',
        calledService: '',
        httpResponse: '',
        httpStatus: '',
        contractor: '',
        contractDateSigned: '',
        numberOfPhotos: '',
        imageId: '',
        fileType: '',
        fileName: '',
        fileSize: '',
      });
      this.login({ extClaimId, extClientId })
        .pipe(
          catchError((error) => {
            this.logger.sendSparklLog({
              messageId: 'COVA_AUTH_TOKEN_RETRIEVAL_ERROR',
              message: `Unable to retrieve auth token: ${error}`,
              logLevel: LogLevel.ERROR,
              reportingFile: 'authlocal.service.ts',
              contractId: '',
              uploadId: '',
              calledService: '',
              httpResponse: '',
              httpStatus: '',
              contractor: '',
              contractDateSigned: '',
              numberOfPhotos: '',
              imageId: '',
              fileType: '',
              fileName: '',
              fileSize: '',
            });
            this.router.navigate(['error-unresolvable'], { queryParamsHandling: 'merge' });
            resolve(null);
            return error;
          }),
          tap((response) => {
            this.logger.sendSparklLog({
              messageId: 'COVA_AUTH_TOKEN_RETRIEVAL_SUCCESS',
              message: `Auth token retrieval success`,
              logLevel: LogLevel.INFO,
              reportingFile: 'authlocal.service.ts',
              contractId: '',
              uploadId: '',
              calledService: '',
              httpResponse: '',
              httpStatus: '',
              contractor: '',
              contractDateSigned: '',
              numberOfPhotos: '',
              imageId: '',
              fileType: '',
              fileName: '',
              fileSize: '',
            });
            resolve(`${response.token}`);
          })
        )
        .subscribe();
    });
  }

  login(ids: { extClaimId: string; extClientId: string }): Observable<any> {
    const url = `${environment.api_auth}/oauth2/token`;
    const authId = 'sf-authentication-api-consumer-rapid-reimbursement';
    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': authId,
      }),
    };
    return this.http.post(url, ids, httpOptions).pipe(tap((response: any) => this.storeJwtToken(response.token)));
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  logout() {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  isValidExtClaimId(extClaimId: string) {
    let isValid = false;
    const regExClaimId = new RegExp('^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$');
    isValid = regExClaimId.test(extClaimId);
    return isValid;
  }

  isValidExtClientId(extClientId: string) {
    let isValid = false;
    const regExClientId = new RegExp('^[a-zA-Z0-9]{20}$');
    isValid = regExClientId.test(extClientId);
    return isValid;
  }

  isTokenValid(extClaimId: string, extClientId: string) {
    const token = localStorage.getItem(this.JWT_TOKEN);
    if (!token) {
      return false;
    }
    const decoded = jwtDecode<JwtPayload & { extClaimId: string; extClientId: string }>(token);
    if (!decoded.exp) {
      this.logout();
      return false;
    }
    const expirationDate = decoded.exp * 1000;
    const now = new Date().getTime();

    // TODO: check if it will expire soon rather than now to make sure the token is useful
    const isExpired = expirationDate < now - 120000;
    if (isExpired) {
      this.logout();
      return false;
    }
    const decodedExtClaimId = decoded.extClaimId;
    const decodedExtClientId = decoded.extClientId;
    if (decodedExtClaimId !== extClaimId) {
      this.logout();
      return false;
    }
    if (decodedExtClientId !== extClientId) {
      this.logout();
      return false;
    }
    this.logger.sendSparklLog({
      messageId: 'COVA_AUTH_TOKEN_STILL_VALID',
      message: `Auth token already available and it is valid`,
      logLevel: LogLevel.INFO,
      reportingFile: 'authlocal.service.ts',
      contractId: '',
      uploadId: '',
      calledService: '',
      httpResponse: '',
      httpStatus: '',
      contractor: '',
      contractDateSigned: '',
      numberOfPhotos: '',
      imageId: '',
      fileType: '',
      fileName: '',
      fileSize: '',
    });
    return true;
  }
}
